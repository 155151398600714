import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _95e116b8 = () => interopDefault(import('../pages/accessibilite.vue' /* webpackChunkName: "pages/accessibilite" */))
const _579d4a21 = () => interopDefault(import('../pages/bureau-etude-urbanisme.vue' /* webpackChunkName: "pages/bureau-etude-urbanisme" */))
const _c09a5920 = () => interopDefault(import('../pages/collectivites-territoriales.vue' /* webpackChunkName: "pages/collectivites-territoriales" */))
const _a0e72608 = () => interopDefault(import('../pages/confidentialite.vue' /* webpackChunkName: "pages/confidentialite" */))
const _0941732d = () => interopDefault(import('../pages/ddt-ddtm-dreal.vue' /* webpackChunkName: "pages/ddt-ddtm-dreal" */))
const _1b45d1a9 = () => interopDefault(import('../pages/exports.vue' /* webpackChunkName: "pages/exports" */))
const _06db2f66 = () => interopDefault(import('../pages/guide.vue' /* webpackChunkName: "pages/guide" */))
const _c2d23cd4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6e60f5e2 = () => interopDefault(import('../pages/loi-climat-et-resilience.vue' /* webpackChunkName: "pages/loi-climat-et-resilience" */))
const _6b303dae = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _bca499da = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _22f32b4c = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _31afec02 = () => interopDefault(import('../pages/validation.vue' /* webpackChunkName: "pages/validation" */))
const _ea6de960 = () => interopDefault(import('../pages/ddt/enquete_overview.vue' /* webpackChunkName: "pages/ddt/enquete_overview" */))
const _5b92df0a = () => interopDefault(import('../pages/dev/api.vue' /* webpackChunkName: "pages/dev/api" */))
const _527e60ea = () => interopDefault(import('../pages/stats/state.vue' /* webpackChunkName: "pages/stats/state" */))
const _e30681dc = () => interopDefault(import('../pages/stats/urba.vue' /* webpackChunkName: "pages/stats/urba" */))
const _4ff4431c = () => interopDefault(import('../pages/login/collectivites/explain.vue' /* webpackChunkName: "pages/login/collectivites/explain" */))
const _3b3c66e6 = () => interopDefault(import('../pages/login/collectivites/signin.vue' /* webpackChunkName: "pages/login/collectivites/signin" */))
const _120fb9fa = () => interopDefault(import('../pages/login/collectivites/signup.vue' /* webpackChunkName: "pages/login/collectivites/signup" */))
const _394a64a0 = () => interopDefault(import('../pages/login/ddt/explain.vue' /* webpackChunkName: "pages/login/ddt/explain" */))
const _44bf6a89 = () => interopDefault(import('../pages/login/ddt/signin.vue' /* webpackChunkName: "pages/login/ddt/signin" */))
const _5955c0ff = () => interopDefault(import('../pages/login/ddt/signup.vue' /* webpackChunkName: "pages/login/ddt/signup" */))
const _527d423c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _08a3d0c8 = () => interopDefault(import('../pages/collectivites/_collectiviteId.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId" */))
const _3a32a14b = () => interopDefault(import('../pages/collectivites/_collectiviteId/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/index" */))
const _65f23da1 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees" */))
const _21bdddf0 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/data.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/data" */))
const _4b01d9bf = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geobretagne.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geobretagne" */))
const _581dbbd2 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geoide.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geoide" */))
const _51a2c3db = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/georisques.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/georisques" */))
const _6f87ae84 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/gpu.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/gpu" */))
const _bfc87a7e = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/inpn.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/inpn" */))
const _c1531444 = () => interopDefault(import('../pages/collectivites/_collectiviteId/glossaire.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/glossaire" */))
const _f4d8e56a = () => interopDefault(import('../pages/collectivites/_collectiviteId/pac.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/pac" */))
const _ae0986d6 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/index" */))
const _23060922 = () => interopDefault(import('../pages/collectivites/_collectiviteId/ressources.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/ressources" */))
const _a1a4c8b8 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/add" */))
const _2e1302e5 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/signup.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/signup" */))
const _a376942e = () => interopDefault(import('../pages/collectivites/_collectiviteId/procedures/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/procedures/add" */))
const _03c67b5e = () => interopDefault(import('../pages/ddt/_departement.vue' /* webpackChunkName: "pages/ddt/_departement" */))
const _42a88814 = () => interopDefault(import('../pages/ddt/_departement/index.vue' /* webpackChunkName: "pages/ddt/_departement/index" */))
const _143812b5 = () => interopDefault(import('../pages/ddt/_departement/collectivites/index.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/index" */))
const _4ab69254 = () => interopDefault(import('../pages/ddt/_departement/pac.vue' /* webpackChunkName: "pages/ddt/_departement/pac" */))
const _702e0b31 = () => interopDefault(import('../pages/ddt/_departement/procedures/index.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/index" */))
const _66fbbd08 = () => interopDefault(import('../pages/ddt/_departement/procedures/choix-collectivite.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/choix-collectivite" */))
const _09bfdd71 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/commune.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/commune" */))
const _5b15f2e0 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/epci.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/epci" */))
const _5e493d94 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/procedure/add.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/procedure/add" */))
const _5de2f2b8 = () => interopDefault(import('../pages/frise/_procedureId/index.vue' /* webpackChunkName: "pages/frise/_procedureId/index" */))
const _1fd0f53d = () => interopDefault(import('../pages/print/_projectId.vue' /* webpackChunkName: "pages/print/_projectId" */))
const _727560b2 = () => interopDefault(import('../pages/trames/_githubRef.vue' /* webpackChunkName: "pages/trames/_githubRef" */))
const _e570f41e = () => interopDefault(import('../pages/documents/_githubRef/pac.vue' /* webpackChunkName: "pages/documents/_githubRef/pac" */))
const _947b02f2 = () => interopDefault(import('../pages/frise/_procedureId/add.vue' /* webpackChunkName: "pages/frise/_procedureId/add" */))
const _7bfc6e93 = () => interopDefault(import('../pages/frise/_procedureId/invite.vue' /* webpackChunkName: "pages/frise/_procedureId/invite" */))
const _6bba40a0 = () => interopDefault(import('../pages/frise/_procedureId/_eventId.vue' /* webpackChunkName: "pages/frise/_procedureId/_eventId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibilite",
    component: _95e116b8,
    name: "accessibilite"
  }, {
    path: "/bureau-etude-urbanisme",
    component: _579d4a21,
    name: "bureau-etude-urbanisme"
  }, {
    path: "/collectivites-territoriales",
    component: _c09a5920,
    name: "collectivites-territoriales"
  }, {
    path: "/confidentialite",
    component: _a0e72608,
    name: "confidentialite"
  }, {
    path: "/ddt-ddtm-dreal",
    component: _0941732d,
    name: "ddt-ddtm-dreal"
  }, {
    path: "/exports",
    component: _1b45d1a9,
    name: "exports"
  }, {
    path: "/guide",
    component: _06db2f66,
    name: "guide"
  }, {
    path: "/login",
    component: _c2d23cd4,
    name: "login"
  }, {
    path: "/loi-climat-et-resilience",
    component: _6e60f5e2,
    name: "loi-climat-et-resilience"
  }, {
    path: "/mentions-legales",
    component: _6b303dae,
    name: "mentions-legales"
  }, {
    path: "/profile",
    component: _bca499da,
    name: "profile"
  }, {
    path: "/stats",
    component: _22f32b4c,
    name: "stats"
  }, {
    path: "/validation",
    component: _31afec02,
    name: "validation"
  }, {
    path: "/ddt/enquete_overview",
    component: _ea6de960,
    name: "ddt-enquete_overview"
  }, {
    path: "/dev/api",
    component: _5b92df0a,
    name: "dev-api"
  }, {
    path: "/stats/state",
    component: _527e60ea,
    name: "stats-state"
  }, {
    path: "/stats/urba",
    component: _e30681dc,
    name: "stats-urba"
  }, {
    path: "/login/collectivites/explain",
    component: _4ff4431c,
    name: "login-collectivites-explain"
  }, {
    path: "/login/collectivites/signin",
    component: _3b3c66e6,
    name: "login-collectivites-signin"
  }, {
    path: "/login/collectivites/signup",
    component: _120fb9fa,
    name: "login-collectivites-signup"
  }, {
    path: "/login/ddt/explain",
    component: _394a64a0,
    name: "login-ddt-explain"
  }, {
    path: "/login/ddt/signin",
    component: _44bf6a89,
    name: "login-ddt-signin"
  }, {
    path: "/login/ddt/signup",
    component: _5955c0ff,
    name: "login-ddt-signup"
  }, {
    path: "/",
    component: _527d423c,
    name: "index"
  }, {
    path: "/collectivites/:collectiviteId?",
    component: _08a3d0c8,
    children: [{
      path: "",
      component: _3a32a14b,
      name: "collectivites-collectiviteId"
    }, {
      path: "donnees",
      component: _65f23da1,
      name: "collectivites-collectiviteId-donnees",
      children: [{
        path: "data",
        component: _21bdddf0,
        name: "collectivites-collectiviteId-donnees-data"
      }, {
        path: "geobretagne",
        component: _4b01d9bf,
        name: "collectivites-collectiviteId-donnees-geobretagne"
      }, {
        path: "geoide",
        component: _581dbbd2,
        name: "collectivites-collectiviteId-donnees-geoide"
      }, {
        path: "georisques",
        component: _51a2c3db,
        name: "collectivites-collectiviteId-donnees-georisques"
      }, {
        path: "gpu",
        component: _6f87ae84,
        name: "collectivites-collectiviteId-donnees-gpu"
      }, {
        path: "inpn",
        component: _bfc87a7e,
        name: "collectivites-collectiviteId-donnees-inpn"
      }]
    }, {
      path: "glossaire",
      component: _c1531444,
      name: "collectivites-collectiviteId-glossaire"
    }, {
      path: "pac",
      component: _f4d8e56a,
      name: "collectivites-collectiviteId-pac"
    }, {
      path: "prescriptions",
      component: _ae0986d6,
      name: "collectivites-collectiviteId-prescriptions"
    }, {
      path: "ressources",
      component: _23060922,
      name: "collectivites-collectiviteId-ressources"
    }, {
      path: "prescriptions/add",
      component: _a1a4c8b8,
      name: "collectivites-collectiviteId-prescriptions-add"
    }, {
      path: "prescriptions/signup",
      component: _2e1302e5,
      name: "collectivites-collectiviteId-prescriptions-signup"
    }, {
      path: "procedures/add",
      component: _a376942e,
      name: "collectivites-collectiviteId-procedures-add"
    }]
  }, {
    path: "/ddt/:departement?",
    component: _03c67b5e,
    children: [{
      path: "",
      component: _42a88814,
      name: "ddt-departement"
    }, {
      path: "collectivites",
      component: _143812b5,
      name: "ddt-departement-collectivites"
    }, {
      path: "pac",
      component: _4ab69254,
      name: "ddt-departement-pac"
    }, {
      path: "procedures",
      component: _702e0b31,
      name: "ddt-departement-procedures"
    }, {
      path: "procedures/choix-collectivite",
      component: _66fbbd08,
      name: "ddt-departement-procedures-choix-collectivite"
    }, {
      path: "collectivites/:collectiviteId/commune",
      component: _09bfdd71,
      name: "ddt-departement-collectivites-collectiviteId-commune"
    }, {
      path: "collectivites/:collectiviteId/epci",
      component: _5b15f2e0,
      name: "ddt-departement-collectivites-collectiviteId-epci"
    }, {
      path: "collectivites/:collectiviteId/procedure/add",
      component: _5e493d94,
      name: "ddt-departement-collectivites-collectiviteId-procedure-add"
    }]
  }, {
    path: "/frise/:procedureId",
    component: _5de2f2b8,
    name: "frise-procedureId"
  }, {
    path: "/print/:projectId?",
    component: _1fd0f53d,
    name: "print-projectId"
  }, {
    path: "/trames/:githubRef?",
    component: _727560b2,
    name: "trames-githubRef"
  }, {
    path: "/documents/:githubRef?/pac",
    component: _e570f41e,
    name: "documents-githubRef-pac"
  }, {
    path: "/frise/:procedureId?/add",
    component: _947b02f2,
    name: "frise-procedureId-add"
  }, {
    path: "/frise/:procedureId?/invite",
    component: _7bfc6e93,
    name: "frise-procedureId-invite"
  }, {
    path: "/frise/:procedureId?/:eventId",
    component: _6bba40a0,
    name: "frise-procedureId-eventId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
