import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0f141659"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutsAppBar: require('/build/b9ccb972-d500-4c1a-b98f-17c31e42c649/components/Layouts/AppBar.vue').default,LayoutsFooter: require('/build/b9ccb972-d500-4c1a-b98f-17c31e42c649/components/Layouts/Footer.vue').default,VConsentSnackbar: require('/build/b9ccb972-d500-4c1a-b98f-17c31e42c649/components/VConsentSnackbar.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VApp,VBtn,VMain,VSnackbar})
